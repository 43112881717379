<template>
  <div id="editDeviceDialog">
    <el-dialog
      :title="title"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="80px"
      >
        <el-form-item label="设备名称" prop="deviceName">
          <el-input
            v-model="addForm.deviceName"
            placeholder="请输入设备名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select v-model="addForm.deviceType" placeholder="请选择设备类型">
            <el-option label="摄像头" value="摄像头"></el-option>
            <el-option label="显示器" value="显示器"></el-option>
            <el-option label="道闸" value="道闸"></el-option>
            <el-option label="对讲" value="对讲"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceCode">
          <el-input
            v-model="addForm.deviceCode"
            placeholder="请输入设备编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器" prop="serverId">
          <el-select v-model="addForm.serverId" placeholder="请选择服务器">
            <el-option
              v-for="item of serverList"
              :key="item.serverId"
              :label="item.serverName"
              :value="item.serverId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      title: "添加设备",
      show: false,
      loading: false,
      serverList: [],
      addForm: {
        deviceName: "",
        deviceType: "",
        deviceCode: "",
        serverId: "",
      },
      addFormRules: {
        deviceName: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
        deviceType: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        deviceCode: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
        ],
        serverId: [
          { required: true, message: "请选择服务器", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getServeList();
  },
  methods: {
    async getServeList() {
      try {
        let res = await this.$http.get("/server/list/all");
        if (res.code === 0) {
          for (let item of res.data) {
            item.serverName = `${item.serverName}（${item.serverIp}）`;
          }
          this.serverList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    reset() {
      this.$set(this, "addForm", {
        deviceName: "",
        deviceType: "",
        deviceCode: "",
        serverId: "",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let url = "/device/add";
        if (this.title === "编辑设备") {
          url = "/device/edit";
        }
        let res = await this.$http.post(url, {
          ...this.addForm,
          channelId: this.$route.params.id,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#editDeviceDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
